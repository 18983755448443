const {
  REACT_APP_PAGARME_APP_ID,
} = process.env

export const pagarmeV5Hash = async (data) => {
  const url = `https://api.pagar.me/core/v5/tokens?appId=${REACT_APP_PAGARME_APP_ID}`
  const options = {
    method: 'POST',
    headers: { accept: 'application/json', 'content-type': 'application/json' },
    body: JSON.stringify(data),
  }

  const result = await fetch(url, options)
    .then(res => res.json())
    .then(json => { return json.id })
    .catch(err => console.error(err))

  return result
}
